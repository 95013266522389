import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from "../containers/common/Header";
import Footer from "../containers/common/Footer";
import TopSection from "../containers/common/TopSection";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
}));

const Privacy: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <TopSection title="プライバシーポリシー"/>
          </Container>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default Privacy;
