import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../containers/common/Header';
import Footer from '../containers/common/Footer';
import Description from '../containers/usage/Description';
import StepOne from '../containers/usage/StepOne';
import StepTwo from '../containers/usage/StepTwo';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 3, 2),
  },
}));

const Usage: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Header />
      <div className={classes.heroContent}>
        <Typography variant={'h4'} align={'center'}>
          ご利用方法
        </Typography>
        <Description />
        <StepOne />
        <StepTwo />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Usage;
