import React from "react";
import Typography from "@material-ui/core/Typography";

type TopSectionProps = {
  title: string;
}

const TopSection: React.FC<TopSectionProps> = ({title} : TopSectionProps) => {
  return (
    <Typography>
      <Typography component="h3" variant="h4" align="center" color="textPrimary">
        {title}
      </Typography>
    </Typography>
  );
};

export default TopSection;