import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import viewMenu from "../../assets/viewmenu.png";
import penlightText from "../../assets/penlightText.png";
import swipe from "../../assets/swipe.png";
import settingText from "../../assets/settingText.png";
import settingMenu from "../../assets/settingmenu.png";
import quitText from "../../assets/quitText.png";
import quit from "../../assets/quit.png";

const StepTwo: React.FC = () => {

  const useStyles = makeStyles((theme) => ({
    pic: {
      maxWidth: "100%",
      padding: theme.spacing(0, 0, 2),
    },
    sectionPic: {
      maxWidth: "100%",
      padding: theme.spacing(2, 0),
    },
    title: {
      padding: theme.spacing(2, 0),
    },
    penLight:{},
    setting:{},
    backTitle:{},
  }));

  const classes = useStyles();

  return (
    <>
      <Typography variant={"h4"} align={"center"} className={classes.title}>
        操作方法
      </Typography>
      <img alt={"ライブ画面"} src={viewMenu} className={classes.pic} />

      <div className={classes.penLight}>
        <img alt={"ペンライトモード切り替え"} src={penlightText} className={classes.sectionPic}/><br/>
        <img alt={"スワイプ説明画像"} src={swipe} className={classes.pic}/><br/>
      </div>

      <div className={classes.setting}>
        <img alt={"各種設定"} src={settingText} className={classes.sectionPic}/><br/>
        <img alt={"設定ダイアログ画像"} src={settingMenu} className={classes.pic}/><br/>
        <Typography>
          ・演出 <br/>
          画面上のCGエフェクトの On/Off を設定できます。<br/><br/>
          ・ペンライト<br/>
          画面上のペンライト機能の On/Off を設定できます。<br/><br/>
          ・モーションと画面の向きの連動<br/>
          端末を傾けてカメラを操作する昨日の On/Off を設定できます。<br/>
        </Typography>
      </div>

      <div className={classes.backTitle}>
        <img alt={"トップへ戻る"} src={quitText} className={classes.sectionPic}/>
        <img alt={"トップへ戻るダイアログ画像"} src={quit} className={classes.pic}/>
      </div>
    </>
  );
};

export default StepTwo;