import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import title from '../../assets/title.png';
import Typography from "@material-ui/core/Typography";


const Description: React.FC = () => {

  const useStyles = makeStyles((theme) => ({
    pic: {
      maxWidth: "100%",
      padding: theme.spacing(2, 0),
    },
    title: {
        maxWidth: "100%",
        padding: theme.spacing(1, 0),
      },
  }));

  const classes = useStyles();

  return (
    <div>
      <img src={title} className={classes.pic}/>
      <div className={classes.title}>
        LIVE DX 360はお客様の購入した席に近いカメラからの360度映像をご視聴いただくことができるアプリケーションです。<br/>
        次世代のライブ体験を体感してみましょう。<br/><br/>
      </div>
    </div>
   );
};

export default Description;