import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import top from '../../assets/top.png';
import login from '../../assets/login.png';
import triangle from '../../assets/triangle.png';

const StepOne: React.FC = () => {
  const useStyles = makeStyles((theme) => ({
    pic: {
      maxWidth: '100%',
      padding: theme.spacing(0, 0, 2),
    },
    trianglePic: {
      maxWidth: '100%',
      padding: theme.spacing(2, 0),
    },
    step: {
      textAlign: 'center',
      padding: theme.spacing(1, 0, 0),
      marginBottom: '20px',
    },
    title: {
      textAlign: 'center',
      padding: theme.spacing(2, 0),
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        ライブ視聴方法
      </Typography>
      <img alt={'タイトル画面'} src={top} className={classes.pic} />
      <br />
      <div className={classes.step}>
        <Typography>①ログインをタップ</Typography>
        <img alt={'矢印画像'} src={triangle} className={classes.trianglePic} />
        <img alt={'ログインダイアログ'} src={login} className={classes.pic} />
        <br />
        <Typography>②メールアドレスを入力</Typography>
        <img alt={'矢印画像'} src={triangle} className={classes.trianglePic} />
        <Typography>③パスワードを入力</Typography>
        <img alt={'矢印画像'} src={triangle} className={classes.trianglePic} />
        <Typography>④OKをタップ</Typography>
      </div>
    </>
  );
};

export default StepOne;
