import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {createMuiTheme, CssBaseline, ThemeProvider} from '@material-ui/core';
import Term from "./pages/term";
import Inquiry from "./pages/inquiry";
import Usage from "./pages/usage";
import Privacy from "./pages/privacy";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f2f27c"
    },
  },
});

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div>
            <Route path="/term" component={Term}/>
            <Route path="/inquiry" component={Inquiry}/>
            <Route path="/usage" component={Usage}/>
            <Route path="/privacy" component={Privacy}/>
          </div>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
