import Typography from "@material-ui/core/Typography";
import React from "react";

const Description: React.FC = () => {
  return(
    <Typography>
      ご質問等ございましたら下記連絡先へお問い合わせください。<br/>
      LIVE DX 360サポートセンター：support@livedx360.com
    </Typography>
  );
}

export default Description;